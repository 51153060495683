<template>
  <div class="w-100">
    <DialogComponent ref="dialogComponent" :contentClass="'dialogSize-80'" :isDialog="isDialog">
      <template #header v-if="isDialog">
        <v-toolbar color="primary" class="white--text" flat>
          <v-toolbar-title> {{ $t("customerselect") }} </v-toolbar-title>
        </v-toolbar>
      </template>

      <template #content>
        <v-card class="search-dialog">
          <v-card-text>
            <v-row class="justify-end" no-gutters>
              <v-btn v-if="canCreate" class="ma-1" @click="NewSupplier()">
                {{ $t("newsupplier") }}
              </v-btn>
              <v-btn v-if="canCreate" class="ma-1" @click="NewClient()">
                {{ $t("newclient") }}
              </v-btn>
            </v-row>

            <v-row no-gutters>
              <!-- Search Input on Top -->

              <!-- Vertical Buttons on the Left Side & List on the Right Side -->
              <v-row v-if="$vuetify.breakpoint.mdAndUp">
                <form class="col-12 d-flex align-center" @submit.prevent="searchData">
                  <BasicTextField
                    v-model="searchCustomer"
                    :label="$t('search')"
                    hide-details
                    clearable
                    clear-icon="mdi-close-circle-outline"
                  />
                  <v-btn
                    class="ma-2"
                    :loading="!isCustomersLoaded"
                    :disabled="!isCustomersLoaded"
                    @click="searchData"
                    color="primary"
                  >
                    {{ $t("display") }}
                  </v-btn>
                </form>

                <v-col cols="3" class="pt-0">
                  <v-row class="mx-0 my-1">
                    <GlobalAutoComplete
                      :label="$t('classifications')"
                      :data="getClassifications"
                      v-model="classification_id"
                      :dataLoading="!isClassificationsLoaded"
                      clearable
                    />

                    <ToolTip
                      @iconClicked="fetchClassifications()"
                      :icon="'mdi-reload'"
                      :name="$t('loadclassifications')"
                      :loading="!isClassificationsLoaded"
                    />
                  </v-row>

                  <v-btn-toggle
                    v-model="selectedPartTypeId"
                    mandatory
                    dense
                    class="type-button vertical-buttons"
                  >
                    <v-btn
                      v-for="type in types"
                      :key="type.id"
                      :value="type.id"
                      outlined
                      class="type-button font-weight-bold vertical-button"
                      @click="loadData(type.id)"
                    >
                      {{ type.label }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>

                <!-- List of Results on the Right Side -->
                <v-col cols="9" class="pt-0">
                  <div v-if="!isCustomersLoaded" class="loader">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>

                  <v-card v-else-if="filteredItems.length" class="results-card">
                    <Pagination
                      v-if="getData.meta && getData.meta.last_page > 1"
                      :dataLoaded="!isCustomersLoaded"
                      :searchData="searchDataQuery"
                      store="ClientSupplierList"
                      collection="data"
                      :getListAction="currentListAction"
                    />

                    <v-virtual-scroll
                      class="results-list"
                      :items="filteredItems"
                      :item-height="40"
                      height="250px"
                    >
                      <template v-slot:default="{ item }">
                        <v-list-item
                          :key="item.id"
                          class="result-item"
                          @click="selectCustomer(item)"
                        >
                          <v-list-item-content class="pa-0 px-7 text-start">
                            <v-list-item-title>
                              <div class="d-flex">
                                {{ item.name }}
                                <v-spacer></v-spacer>
                                {{ item.phone }}
                              </div>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                      </template>
                    </v-virtual-scroll>
                  </v-card>
                  <v-card v-else class="no-data-card">
                    <v-card-text class="text-center">
                      {{ $t("nodata") }}
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-row v-else class="ma-0">
                <!-- label="Select Category" -->
                <v-select
                  v-model="selectedPartTypeId"
                  dense
                  :items="types"
                  item-text="label"
                  item-value="id"
                  outlined
                  hide-details=""
                ></v-select>

                <v-col cols="12" class="px-0">
                  <BasicTextField
                    v-model="searchCustomer"
                    :label="$t('search')"
                    hide-details
                    clearable
                    clear-icon="mdi-close-circle-outline"
                  />
                </v-col>

                <v-col cols="12" class="d-flex px-0">
                  <GlobalAutoComplete
                    :label="$t('classifications')"
                    :data="getClassifications"
                    v-model="classification_id"
                    :dataLoading="!isClassificationsLoaded"
                    clearable
                  />

                  <ToolTip
                    @iconClicked="fetchClassifications()"
                    :icon="'mdi-reload'"
                    :name="$t('loadclassifications')"
                    :loading="!isClassificationsLoaded"
                  />
                </v-col>

                <v-col cols="12" class="text-start">
                  <v-btn
                    @click="searchData"
                    :loading="!isCustomersLoaded"
                    :disabled="!isCustomersLoaded"
                    color="primary"
                  >
                    {{ $t("display") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-row>
          </v-card-text>
        </v-card>

        <div v-if="$vuetify.breakpoint.mdAndDown">
          <div v-if="!isCustomersLoaded" class="loader">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>

          <v-card v-else-if="filteredItems.length" class="results-card">
            <Pagination
              v-if="getData.meta && getData.meta.last_page > 1"
              :dataLoaded="!isCustomersLoaded"
              :searchData="searchDataQuery"
              store="ClientSupplierList"
              collection="data"
              :getListAction="currentListAction"
            />

            <v-virtual-scroll
              class="results-list"
              :items="filteredItems"
              :item-height="40"
              height="250px"
            >
              <template v-slot:default="{ item }">
                <v-list-item :key="item.id" class="result-item" @click="selectCustomer(item)">
                  <v-list-item-content class="pa-0 px-7 text-start">
                    <v-list-item-title>
                      <div class="d-flex">
                        {{ item.name }}
                        <v-spacer></v-spacer>
                        {{ item.phone }}
                      </div>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>
            </v-virtual-scroll>
          </v-card>
          <v-card v-else class="no-data-card">
            <v-card-text class="text-center"> {{ $t("nodata") }} </v-card-text>
          </v-card>
        </div>
      </template>
    </DialogComponent>

    <NewClient
      v-if="canCreate"
      ref="clientChildComponent"
      :isClientsLoaded="isClientsLoaded"
      :getClientErrors="getClientErrors"
      :fastCreate="true"
      @updateClient="selectCustomer"
      :getSalesAgents="getSalesAgents"
      :isSalesAgentsLoaded="isSalesAgentsLoaded"
    />

    <NewSupplier
      v-if="canCreate"
      ref="supplierChildComponent"
      :isSuppliersLoaded="isSuppliersLoaded"
      :getSupplierErrors="getSupplierErrors"
      :fastCreate="true"
      @updateClient="selectCustomer"
      :getSalesAgents="getSalesAgents"
      :isSalesAgentsLoaded="isSalesAgentsLoaded"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import NewClient from "@constructions/Clients/CreateForm";
import NewSupplier from "@constructions/Suppliers/CreateForm";
import Pagination from "@composables/SearchPagination.vue";

export default {
  props: {
    type: {
      type: Number,
      default: () => null,
    },
    isDialog: {
      type: Boolean,
      default: () => true,
    },
    canCreate: {
      type: Boolean,
      default: () => false,
    },
    branchId: {
      type: Number,
      default: () => null,
    },
    loadExtraInfo: {
      type: Boolean,
      default: () => true,
    },
    fetchAccountBalance: {
      type: Boolean,
      default: () => true,
    },
  },

  data: () => ({
    selectedPartTypeId: -1,
    searchCustomer: "",

    classification_id: null,
  }),
  components: {
    NewClient,
    NewSupplier,
    Pagination,
  },

  computed: {
    ...mapGetters("ClientSupplierList", ["getData"]),
    ...mapGetters("ClientSupplierList", ["isCustomersLoaded"]),
    ...mapGetters("Clients", ["getClientErrors"]),
    ...mapGetters("Clients", ["isClientsLoaded"]),
    ...mapGetters("Suppliers", ["getSupplierErrors"]),
    ...mapGetters("Suppliers", ["isSuppliersLoaded"]),

    ...mapGetters("Employees", ["getSalesAgents"]),
    ...mapGetters("Employees", ["isSalesAgentsLoaded"]),

    ...mapGetters("ClientClassifications", ["getClassifications"]),
    ...mapGetters("ClientClassifications", ["isClassificationsLoaded"]),

    filteredItems() {
      if (!this.getData || !this.getData.data) return [];

      if (!this.searchCustomer) return this.getData.data;

      const lowercasedSearch = this.searchCustomer.toLowerCase();
      return this.getData.data.filter(
        (result) =>
          result.name.toLowerCase().includes(lowercasedSearch) ||
          result.phone?.toLowerCase().includes(lowercasedSearch)
      );
    },

    searchDataQuery() {
      return {
        name: this.searchCustomer,
      };
    },

    currentListAction() {
      let result = this.types.find((o) => o.id === this.selectedPartTypeId);
      return result?.actionName;
    },

    types() {
      let types = [
        {
          id: 1,
          label: this.$t("clients"),
          basicAction: this.fetchClientsList,
          actionName: "fetchClientsList",
          singleFetch: this.fetchClient,
        },
        {
          id: 2,
          label: this.$t("suppliers"),
          basicAction: this.fetchSuppliersList,
          actionName: "fetchSuppliersList",
          singleFetch: this.fetchSupplier,
        },
        {
          id: 3,
          label: this.$t("employees"),
          basicAction: this.fetchEmployeesList,
          actionName: "fetchEmployeesList",
          singleFetch: this.fetchEmployee,
        },
      ];

      if (this.companyPermissionChecker(this.CompanyPermissions.maintenance_management)) {
        let maintenanceClients = [
          {
            id: 5,
            label: this.$t("maintenanceclients"),
            basicAction: this.fetchMaintenanceClientsList,
            actionName: "fetchMaintenanceClientsList",
            singleFetch: this.fetchMaintenance,
          },
        ];
        types = [...types, ...maintenanceClients];
      }

      if (this.companyPermissionChecker(this.CompanyPermissions.cleanning_management)) {
        let maintenanceClients = [
          {
            id: 6,
            label: this.$t("cleanningclients"),
            basicAction: this.fetchCleanningClientsList,
            actionName: "fetchCleanningClientsList",
            singleFetch: this.fetchCleanning,
          },
        ];
        types = [...types, ...maintenanceClients];
      }
      return types;
    },
  },

  methods: {
    ...mapActions("ClientSupplierList", ["fetchClientsList"]),
    ...mapActions("ClientSupplierList", ["fetchSuppliersList"]),
    ...mapActions("ClientSupplierList", ["fetchEmployeesList"]),
    ...mapActions("ClientSupplierList", ["fetchMaintenanceClientsList"]),
    ...mapActions("ClientSupplierList", ["fetchCleanningClientsList"]),
    ...mapActions("ClientSupplierList", ["fetchCustomerBalance"]),

    ...mapActions("ClientClassifications", ["fetchClassifications"]),
    ...mapActions("Employees", ["fetchSalesAgents"]),

    ...mapActions("ClientSupplierList", ["fetchClient"]),
    ...mapActions("ClientSupplierList", ["fetchSupplier"]),
    ...mapActions("ClientSupplierList", ["fetchEmployee"]),
    ...mapActions("ClientSupplierList", ["fetchMaintenance"]),
    ...mapActions("ClientSupplierList", ["fetchCleanning"]),

    async selectCustomer(customer) {
      // this.$emit("updateClient", customer);
      // this.$refs?.dialogComponent?.closeDialog();
      // this.$refs?.clientChildComponent?.closeDialog();
      // this.$refs?.supplierChildComponent?.closeDialog();
      // if (this.fetchAccountBalance) {
      //   this.fetchCustomerBalance({
      //     part_id: customer.id,
      //     part_type_id: customer.part_type_id,
      //   }).then((res) => {
      //     let data = res.data;
      //     console.log(data);
      //     // this.$emit(
      //     //   "updateClientBalance",
      //     //   (data.total_debits - data.total_credits).toFixed(2)
      //     // );
      //   });
      // }
      if (this.loadExtraInfo) {
        let result = this.types.find((o) => o.id === this.selectedPartTypeId);
        if (result.singleFetch) {
          await result
            .singleFetch({
              id: customer.id,
              data: {
                fetch_balance: this.fetchAccountBalance,
              },
            })
            .then((res) => {
              this.$emit("updateClient", res);
            });
        }
      } else {
        this.$emit("updateClient", customer);
      }
      this.$refs?.dialogComponent?.closeDialog();
      this.$refs?.clientChildComponent?.closeDialog();
      this.$refs?.supplierChildComponent?.closeDialog();
    },

    openDialog(data) {
      this.$refs.dialogComponent?.openDialog();

      if (data.part_id && data.part_type_id) {
        this.selectedPartTypeId = data.part_type_id;
        this.loadData(data.part_type_id);
      } else if (this.type) {
        this.selectedPartTypeId = this.type;
        this.loadData(this.type);
      }
    },
    dblClicked() {
      this.$emit("dblClicked");
    },

    searchData() {
      if (this.isCustomersLoaded) {
        let result = this.types.find((o) => o.id === this.selectedPartTypeId);
        result?.basicAction({
          data: {
            name: this.searchCustomer,
            branch_id: this.branchId,
            classification_id: this.classification_id,
          },
        });
      }
    },
    loadData(typeId) {
      this.selectedPartTypeId = typeId;

      let result = this.types.find((o) => o.id === typeId);
      if (result.basicAction) {
        result.basicAction({
          data: {
            branch_id: this.branchId,
            classification_id: this.classification_id,
          },
        });
      }
    },

    NewClient() {
      this.fetchSalesAgents();
      this.$refs.clientChildComponent?.openDialog();
    },
    NewSupplier() {
      this.fetchSalesAgents();
      this.$refs.supplierChildComponent?.openDialog();
    },
  },
  mounted() {
    this.selectedPartTypeId = this.type - 1;
  },
};
</script>

<style lang="scss" scoped>
$search-dialog-bg-color: var(--v-background-base);
$result-hover-bg-color: rgba(0, 0, 0, 0.1); // Adjust this color to your preference
$result-hover-transform: translateY(-3px);

.v-application--is-ltr .v-btn-toggle > .v-btn.v-btn:not(:first-child) {
  border-left-width: 1px;
}

.v-list-item {
  min-height: unset !important;
}

.vertical-buttons {
  display: flex;
  flex-direction: column;
}

.vertical-button + .vertical-button {
  margin-top: 5px; /* spacing between vertical buttons */
}

.search-dialog {
  background-color: $search-dialog-bg-color;
  color: var(--v-text-base);
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);

  .type-buttons {
    margin-bottom: 20px;
    max-height: 150px; // Adjust this value as needed
    overflow-y: auto; // Vertical scroll
  }

  .type-button,
  .search-field {
    color: var(--v-text-base);
    transition: 0.3s;
  }
}

.results-card {
  background-color: var(--v-surface-base);
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  // padding: 20px;
  padding: 0px;

  .result-item {
    border-bottom: 1px solid var(--v-divider-base);
    padding: 7px 0;
    transition: background-color 0.3s, transform 0.2s, box-shadow 0.2s;
    cursor: pointer;
    &:hover {
      background-color: $result-hover-bg-color;
      transform: $result-hover-transform;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); // Subtle shadow for depth effect
    }
  }
}

.v-icon {
  transition: color 0.3s;
}

@media (max-width: 600px) {
  .search-dialog,
  .results-card {
    width: 90vw;
    font-size: 1rem;
    overflow-x: hidden;

    .type-button,
    .v-btn {
      padding: 12px 16px;
      font-size: 1rem;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 250px;
  width: 100%;
}

.no-data-card {
  background-color: var(--v-surface-base);
  border-radius: 10px;
  margin-top: 20px;
  padding: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5); // you can adjust the color
  min-height: 200px;
  display: flex;
  align-items: center;
}
</style>
