import i18n from "@/plugins/i18n/i18n";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  toastMessage: ({}, message) => {
    // iziToast.question({
    //   timeout: null,
    //   close: false,
    //   overlay: true,
    //   displayMode: "once",
    //   messageColor: "#fff",
    //   backgroundColor: "#3f51b5",
    //   // messageLineHeight: "500",
    //   icon: "",
    //   title: '<span style="color:#fff;" class="mdi mdi-alert-octagon-outline"></span>',
    //   message: message,
    //   position: "center",
    //   class: "question-toast",
    //   buttons: [
    //     [
    //       `<button fdprocessedid="obblvh" style="color:#fff;"><b>${i18n.t("close")}</b></button>`,
    //       function (instance, toast) {
    //         instance.hide({ transitionOut: "fadeOut" }, toast, "button");
    //       },
    //       true,
    //     ],
    //   ],
    // });

    iziToast.question({
      timeout: null,
      close: false,
      displayMode: "once",
      icon: "",
      title: '<span class="mdi mdi-alert-octagon-outline"></span>',
      message: message,
      position: "topCenter",
      buttons: [
        [
          `<button fdprocessedid="obblvh"><b>${i18n.t("close")}</b></button>`,
          function (instance, toast) {
            instance.hide({ transitionOut: "fadeOut" }, toast, "button");
          },
          true,
        ],
      ],
    });
  },

  registerPublicChannel: ({ rootGetters, dispatch }) => {
    let Authenticated = rootGetters["User/getAuthentication"];
    if (Authenticated) {
      Echo.channel("events").listen("PublicMessage", (e) => {
        // console.log("PublicMessage: " + e.message);
        dispatch("toastMessage", e.message);
      });
    }
  },
  registerCompanyChannel: ({ rootGetters, dispatch }) => {
    let Authenticated = rootGetters["User/getAuthentication"];
    let company = JSON.parse(localStorage.getItem("company"));
    if (Authenticated && company) {
      let companyName = company.name.replace(/\s+/g, "_");

      Echo.channel(`company-${companyName}`).listen("CompanyMessage", (e) => {
        // console.log("Company RealTimeMessage: " + e.message);
        dispatch("toastMessage", e.message);
        dispatch("Notifications/fetchNotifications", {}, { root: true });
      });
    }
  },

  stopListeningToChannels: ({ rootGetters }) => {
    Echo.disconnect();

    // let Authenticated = rootGetters["User/getAuthentication"];
    // let company = JSON.parse(localStorage.getItem("company"));

    // if (Authenticated) {
    //   Echo.leave(`events`);
    //   if (company) {
    //     let companyName = company.name.replace(/\s+/g, "_");
    //     Echo.leave(`company-${companyName}`);
    //   }
    // }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
