import axios from "axios";
import { UserAPI } from "@/helpers/Apis/User/Users";
import router from "@/router";
import VueCookies from "vue-cookies";
import i18n from "@/plugins/i18n/i18n";

const state = {
  user: JSON.parse(localStorage.getItem("user")) || null,
  isUserLoaded: true,
  token: VueCookies.get("token") || null,
  errors: [],
  begin_date: JSON.parse(localStorage.getItem("begin_date")) || null,
};

const getters = {
  getUser(state) {
    return state.user;
  },
  getUserAccounts(state) {
    return state.user.accounts;
  },
  isUserLoaded(state) {
    return state.isUserLoaded;
  },
  getBeginDate(state) {
    return state.begin_date;
  },
  getAuthentication(state) {
    return state.token != null;
  },
  getErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setUser: (state, payload) => {
    // setting user
    localStorage.setItem("user", JSON.stringify(payload.user));
    state.user = payload.user;
    // setting token
    VueCookies.set("token", JSON.stringify(payload.token), "7d");
    state.token = payload.token;

    // setting beginnning date
    localStorage.setItem("begin_date", JSON.stringify(payload.begin_date));
    state.begin_date = payload.begin_date;
  },
  setIsUserLoaded: (state, payload) => {
    state.isUserLoaded = payload;
  },

  setUserName: (state, payload) => {
    let user = JSON.parse(localStorage.getItem("user"));
    user.name = payload;
    state.user.name = payload;
    localStorage.setItem("user", JSON.stringify(user));
  },

  setDBSettings: (state, payload) => {
    state.user.database_settings = payload;
  },

  setLogout: (state) => {
    state.token = null;
    state.user = {};
    if (router.currentRoute.name != "Login") {
      router.push({
        name: "Login",
      });
    }

    //remove token from cookies
    localStorage.removeItem("user");
    localStorage.removeItem("company");
    localStorage.removeItem("begin_date");
    localStorage.removeItem("db");
    VueCookies.remove("token");
    //remove token from header
    axios.defaults.headers.common["Authorization"] = null;

    Echo.disconnect();
  },
  setErrors: (state, payload) => {
    state.errors = payload;
  },

  setAssociates(state, payload) {
    let user = JSON.parse(localStorage.getItem("user"));
    user.associates = payload;

    state.user = user;
    localStorage.setItem("user", JSON.stringify(user));
  },

  // User Associates Stores Drawers Branches
  setAssociate(state, payload) {
    let data = payload.data;
    let associate = payload.type;
    let user = JSON.parse(localStorage.getItem("user"));
    let userAssociates = user[associate];

    // // Check if the userAssociates is not an array or is empty
    // if (!Array.isArray(userAssociates) || !userAssociates.length) {
    let associateIndex = user[associate].findIndex((o) => o.id === data.id);
    // Check if associate is found
    if (associateIndex !== -1) {
      user[associate][associateIndex] = data;
    } else {
      user[associate].push(data);
    }
    state.user = user; // Update Vuex state
    localStorage.setItem("user", JSON.stringify(user)); // Update local storage
    // }
  },
  setEmployee(state, payload) {
    let user = JSON.parse(localStorage.getItem("user"));
    let userBranchIndex = user.branches.findIndex((o) => o.id === payload.branch_id);
    if (userBranchIndex == -1) {
      return;
    }

    let employeeIndex = user.employees.findIndex((o) => o.id === payload.id);
    if (employeeIndex !== -1) {
      user.employees[employeeIndex] = payload;
    } else {
      user.employees.push(payload);
    }
    let branchEmployeeIndex = user.branches[userBranchIndex].sales_agents.findIndex(
      (o) => o.id === payload.id
    );
    if (branchEmployeeIndex !== -1) {
      user.branches[userBranchIndex].sales_agents[branchEmployeeIndex] = payload;
    } else {
      user.branches[userBranchIndex].sales_agents.push(payload);
    }
    state.user = user; // Update Vuex state
    localStorage.setItem("user", JSON.stringify(user)); // Update local storage
  },

  deleteEmployee(state, payload) {
    let user = JSON.parse(localStorage.getItem("user"));
    let userBranchIndex = user.branches.findIndex((o) => o.id === payload.branch_id);
    if (userBranchIndex == -1) {
      return;
    }
    let employeeIndex = user.employees.findIndex((o) => o.id === payload.id);
    if (employeeIndex !== -1) {
      user.employees.splice(employeeIndex, 1); // Remove Employee
    }
    let branchEmployeeIndex = user.branches[userBranchIndex].sales_agents.findIndex(
      (o) => o.id === payload.id
    );
    if (branchEmployeeIndex !== -1) {
      user.branches[userBranchIndex].sales_agents.splice(branchEmployeeIndex, 1); // Remove Employee
    }
    state.user = user; // Update Vuex state
    localStorage.setItem("user", JSON.stringify(user)); // Update local storage
  },

  deleteAssociate(state, payload) {
    let data = payload.data;
    let associate = payload.type;
    let user = JSON.parse(localStorage.getItem("user"));
    let associateIndex = user[associate].findIndex((o) => o.id === data);

    // Check if Associate is found
    if (associateIndex !== -1) {
      user[associate].splice(associateIndex, 1); // Remove Associate
      state.user = user; // Update Vuex state
      localStorage.setItem("user", JSON.stringify(user)); // Update local storage
    }
  },

  setUserQuickLinks(state, payload) {
    let user = JSON.parse(localStorage.getItem("user"));
    user.quick_links = payload;
    state.user = user;
    localStorage.setItem("user", JSON.stringify(user));
  },
};

const actions = {
  login: ({ commit, dispatch }, data) => {
    commit("setIsUserLoaded", false);
    return new Promise((resolve, reject) => {
      UserAPI.login(data)
        .then((res) => {
          let resData = res.data.data;
          let user = res.data.data.user;
          commit("setUser", resData);
          commit("setIsUserLoaded", true);
          commit("setErrors", "");

          commit("DBSettings/setDBSettings", user.database_settings, {
            root: true,
          });
          commit("Notifications/setNotifications", user.notifications, {
            root: true,
          });
          commit("Company/setCompanyData", data.company, {
            root: true,
          });
          commit("Company/setDatabase", data.database, {
            root: true,
          });

          dispatch("Sockets/registerCompanyChannel", {}, { root: true });
          dispatch("Sockets/registerPublicChannel", {}, { root: true });

          resolve(res);
          // router.push({ name: "Home" });
          // router.push({ name: "Home" }).then(() => {
          //   window.location.reload();
          // });
          window.location.href = "/";
        })
        .catch((error) => {
          commit("setIsUserLoaded", true);
          // commit logout
          if (error.response.status != 409) {
            commit("setLogout");
          }
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  forceLogin: ({ commit, dispatch }, data) => {
    commit("setIsUserLoaded", false);
    return new Promise((resolve, reject) => {
      UserAPI.forceLogin(data)
        .then((res) => {
          let resData = res.data.data;
          let user = res.data.data.user;
          commit("setUser", resData);
          commit("setIsUserLoaded", true);
          commit("setErrors", "");

          commit("DBSettings/setDBSettings", user.database_settings, {
            root: true,
          });
          commit("Notifications/setNotifications", user.notifications, {
            root: true,
          });
          commit("Company/setCompanyData", data.company, {
            root: true,
          });
          commit("Company/setDatabase", data.database, {
            root: true,
          });

          dispatch("Sockets/registerCompanyChannel", {}, { root: true });
          dispatch("Sockets/registerPublicChannel", {}, { root: true });

          resolve(res);
          // router.push({ name: "Home" });
          // router.push({ name: "Home" }).then(() => {
          //   window.location.reload();
          // });
          window.location.href = "/";
        })
        .catch((error) => {
          commit("setIsUserLoaded", true);
          // commit logout
          commit("setLogout");
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  fetchUser: ({ commit, dispatch }) => {
    commit("setIsUserLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    UserAPI.fetchUser()
      .then((res) => {
        commit("App/setAppLoader", false, { root: true });
        let resData = res.data.data;
        commit("setUser", resData);
        commit("setIsUserLoaded", true);
        commit("DBSettings/setDBSettings", resData.user.database_settings, {
          root: true,
        });
        commit("Notifications/setNotifications", resData.user.notifications, {
          root: true,
        });
      })
      .catch((error) => {
        commit("setIsUserLoaded", true);
        commit("App/setAppLoader", false, { root: true });
        commit("setLogout");
      });
  },
  reloadUser: ({ commit }) => {
    commit("setIsUserLoaded", false);
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      UserAPI.reloadUser()
        .then((res) => {
          let resData = res.data.data;
          commit("setUser", resData);
          commit("DBSettings/setDBSettings", resData.user.database_settings, {
            root: true,
          });
          commit("Notifications/setNotifications", resData.user.notifications, {
            root: true,
          });
          commit("Company/setCompanyData", resData.company, {
            root: true,
          });

          commit("setErrors", "");
          commit("setIsUserLoaded", true);
          commit("App/setAppLoader", false, { root: true });
          resolve(res);
        })
        .catch((error) => {
          commit("setIsUserLoaded", true);
          commit("App/setAppLoader", false, { root: true });

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  logout: ({ commit, dispatch }) => {
    commit("App/setAppLoader", true, { root: true });
    return new Promise((resolve, reject) => {
      UserAPI.logout()
        .then((res) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setLogout");
          resolve(res);
        })
        .catch((error) => {
          commit("App/setAppLoader", false, { root: true });
          commit("setLogout");
          reject(error);
        });
    });
  },

  updateName: ({ commit }, data) => {
    commit("setIsUserLoaded", false);
    return new Promise((resolve, reject) => {
      UserAPI.updateName(data)
        .then((res) => {
          commit("setUserName", data.name);
          commit("setIsUserLoaded", true);
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsUserLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  updatePassword: ({ commit }, data) => {
    commit("setIsUserLoaded", false);
    return new Promise((resolve, reject) => {
      UserAPI.updatePassword(data)
        .then((res) => {
          commit("setIsUserLoaded", true);
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          commit("setIsUserLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  updateQuickLinks: ({ commit }, data) => {
    commit("setIsUserLoaded", false);
    return new Promise((resolve, reject) => {
      UserAPI.updateQuickLinks(data)
        .then((res) => {
          commit("setIsUserLoaded", true);
          commit("setErrors", "");
          commit("setUserQuickLinks", res.data.data);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsUserLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  checkAdminPass({ commit }, data) {
    commit("setIsUserLoaded", false);
    return new Promise((resolve, reject) => {
      UserAPI.checkAdminPass(data)
        .then((res) => {
          commit("setIsUserLoaded", true);
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          // commit logout
          commit("setIsUserLoaded", true);
          reject(error.response.data);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
  checkSupportPass({ commit }, data) {
    commit("setIsUserLoaded", false);
    return new Promise((resolve, reject) => {
      UserAPI.checkSupportPass(data)
        .then((res) => {
          commit("setIsUserLoaded", true);
          commit("setErrors", "");
          resolve(res);
        })
        .catch((error) => {
          // commit logout
          commit("setIsUserLoaded", true);
          reject(error.response.data);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
