import { RestaurantMealAPI } from "@/helpers/Apis/index";
import i18n from "@/plugins/i18n/i18n";

const state = {
  meals: [],
  errors: [],
  isMealsLoaded: true,

  cachedMeals: [],
  basicMeals: [],
};

const getters = {
  isMealsLoaded(state) {
    return state.isMealsLoaded;
  },
  getMeals(state) {
    return state.meals;
  },
  getCachedMeals(state) {
    return state.cachedMeals;
  },

  getBasicMeals(state) {
    return state.basicMeals;
  },

  getMealErrors(state) {
    return state.errors;
  },
};

const mutations = {
  setIsMealsLoaded: (state, payload) => {
    state.isMealsLoaded = payload;
  },
  setMeals: (state, payload) => {
    state.meals = payload;
  },
  setCachedMeals: (state, payload) => {
    state.cachedMeals = payload;
  },

  setBasicMeals: (state, payload) => {
    state.basicMeals = payload;
  },

  setErrors: (state, payload) => {
    state.errors = payload;
  },
  setCurrentPage(state, payload) {
    state.meals.meta.current_page = payload.value;
  },

  setNewUpdateMeal(state, payload) {
    let mealIndex = state.meals.data.findIndex((o) => o.id === payload.id);
    if (mealIndex !== -1) {
      state.meals.data.splice(mealIndex, 1, { ...payload });
    } else {
      state.meals.data.unshift(payload);
    }
  },
  deleteMeal(state, payload) {
    let mealIndex = state.meals.data.findIndex((o) => o.id === payload);
    if (mealIndex !== -1) {
      state.meals.data.splice(mealIndex, 1);
    }
  },
};

const actions = {
  fetchMeals: ({ commit }) => {
    commit("setIsMealsLoaded", false);
    return new Promise((resolve, reject) => {
      RestaurantMealAPI.fetchMeals()
        .then((res) => {
          commit("setMeals", res.data);
          commit("setIsMealsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsMealsLoaded", true);
          reject(error);
        });
    });
  },

  fetchCachedMeals: ({ commit }) => {
    commit("setIsMealsLoaded", false);
    return new Promise((resolve, reject) => {
      RestaurantMealAPI.fetchCachedMeals()
        .then((res) => {
          commit("setCachedMeals", res.data);
          commit("setIsMealsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsMealsLoaded", true);
          reject(error);
        });
    });
  },

  createMeal: ({ commit }, data) => {
    commit("setIsMealsLoaded", false);
    return new Promise((resolve, reject) => {
      RestaurantMealAPI.createMeal(data)
        .then((res) => {
          commit("setIsMealsLoaded", true);
          commit("setErrors", "");
          commit("setNewUpdateMeal", res.data.data);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsMealsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  updateMeal: ({ commit }, data) => {
    commit("setIsMealsLoaded", false);
    return new Promise((resolve, reject) => {
      RestaurantMealAPI.updateMeal(data)
        .then((res) => {
          commit("setIsMealsLoaded", true);
          commit("setErrors", "");
          commit("setNewUpdateMeal", res.data.data);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsMealsLoaded", true);

          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },
  deleteMeal: ({ commit }, id) => {
    commit("setIsMealsLoaded", false);
    return new Promise((resolve, reject) => {
      RestaurantMealAPI.deleteMeal(id)
        .then((res) => {
          commit("setErrors", "");
          commit("deleteMeal", id);
          commit("setIsMealsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsMealsLoaded", true);
          let nameError = error.response.data.errors
            ? Object.values(error.response.data.errors)
            : [error.response.data.message];
          commit("setErrors", nameError);
          reject(error);
        });
    });
  },

  fetchMealProportions: ({ commit }, id) => {
    commit("setIsMealsLoaded", false);
    return new Promise((resolve, reject) => {
      RestaurantMealAPI.fetchMealProportions(id)
        .then((res) => {
          commit("setIsMealsLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsMealsLoaded", true);
          reject(error);
        });
    });
  },

  clearCache: ({ commit }) => {
    commit("setIsMealsLoaded", false);
    return new Promise((resolve, reject) => {
      RestaurantMealAPI.clearCache()
        .then((res) => {
          commit("setMeals", res.data.data);
          commit("setIsMealsLoaded", true);
          resolve(res.data);
        })
        .catch((error) => {
          commit("setIsMealsLoaded", true);
          reject(error);
        });
    });
  },

  fetchBasicMeals: ({ commit }) => {
    commit("setIsMealsLoaded", false);
    return new Promise((resolve, reject) => {
      RestaurantMealAPI.fetchBasicMeals()
        .then((res) => {
          commit("setBasicMeals", res.data);
          commit("setIsMealsLoaded", true);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsMealsLoaded", true);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
