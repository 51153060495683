import { SaleProfitAPI } from "@/helpers/Apis/Invoices/Sales/Reports/Profits";
import { InvoiceTypeIds } from "@/helpers/Constants/General";
import i18n from "@/plugins/i18n/i18n";

const state = {
  ItemsProfits: [],
  errors: [],
  isProfitsLoaded: true,

  currentPage: null,
};

const getters = {
  isProfitsLoaded(state) {
    return state.isProfitsLoaded;
  },
  getItemsProfits(state) {
    return state.ItemsProfits;
  },

  getDetails(state) {
    return state.ItemsProfits?.data?.filter(function (el) {
      return el.invoicetype_id != InvoiceTypeIds.salereturn;
    });
  },
  getReturnDetails(state) {
    return state.ItemsProfits?.data?.filter(function (el) {
      return el.invoicetype_id == InvoiceTypeIds.salereturn;
    });
  },

  getReportErrors(state) {
    return state.errors;
  },

  getCurrentPage(state) {
    return state.currentPage;
  },
};

const mutations = {
  setIsProfitsLoaded: (state, payload) => {
    state.isProfitsLoaded = payload;
  },
  setItemsProfits: (state, payload) => {
    state.ItemsProfits = payload;
  },
  setReportErrors: (state, payload) => {
    state.errors = payload;
  },

  setCurrentPage(state, payload) {
    state.ItemsProfits.meta.current_page = payload.value;
  },
};

const actions = {
  fetchItemsProfits: ({ commit }, data) => {
    commit("setIsProfitsLoaded", false);
    return new Promise((resolve, reject) => {
      SaleProfitAPI.fetchItemsProfits(data)
        .then((res) => {
          commit("setIsProfitsLoaded", true);
          commit("setItemsProfits", res.data);
          resolve(res);
        })
        .catch((error) => {
          commit("setIsProfitsLoaded", true);
          let nameError = Object.values(error.response.data.errors);
          commit("setReportErrors", nameError[0]);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
